import { PageFooter, GoBackButton, PageHeader, PageLayout } from "../layouts/PageLayout"


const Proyectos = () => {
  return (
    <PageLayout
      header={<PageHeader>Proyectos colectivos</PageHeader>}
      footer={
        <PageFooter>
          <GoBackButton>Regresar</GoBackButton>
        </PageFooter>
      }
    >
      <div>
        Cuando compartimos se disfruta mejor, proyectos en colaboración, nos
        permite extendernos internacionalmente y crear en conjunto aquellos
        proyectos que no los harías a menos que alguien se sumará contigo.
      </div>
    </PageLayout>
  )
}

export default Proyectos
