import { useState, useEffect } from "react"

export const useBreakpoint = (breakpoint) => {
  const [isMatch, setIsMatch] = useState(false)

  useEffect(() => {
    function handleResize() {
      setIsMatch(window.matchMedia(`(max-width: ${breakpoint}px)`).matches)
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [breakpoint])

  return isMatch
}
