const HomeElement = ({
  date = null,
  label = "",
  backgroundSrc = "",
  onClick = () => {},
}) => {
  return (
    <div className="relative w-64 h-64 md:h-52 md:w-52 m-8 cursor-pointer" onClick={() => onClick()}>
      <div
        className="absolute inset-0 p-2"
        style={{
          backgroundImage: backgroundSrc,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* <div
          className="w-full h-full bg-green-600"
          style={{
            backgroundImage: backgroundSrc,
          }}
        ></div> */}
      </div>

      <div
        className="absolute w-full h-full md:hover:opacity-100 md:opacity-0 transition-opacity flex justify-center items-center"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      >
        <div className="text-white font-bold w-3/4">
          {date && <div className="text-xs">{date}</div>}
          <div className="text-xl">{label}</div>
        </div>
      </div>
    </div>
  )
}

export default HomeElement
