import { PageFooter, GoBackButton, PageHeader, PageLayout } from "../layouts/PageLayout"

const Experiencias = () => {
  return (
    <PageLayout
      header={<PageHeader>Experiencias</PageHeader>}
      footer={
        <PageFooter>
          <GoBackButton>Regresar</GoBackButton>
        </PageFooter>
      }
    >
      <div>
        Desde la desmaterialización de un producto a la fibra emocional que es
        conmovida por la relación entre un espacio o un objeto, diseño de
        experiencias como parte del producto o servicio.
      </div>
    </PageLayout>
  )
}

export default Experiencias
