import { useHistory } from "react-router-dom"
import NdlLogoUrl from "../assets/ndl_logo.svg"

const NavBar = () => {
  let history = useHistory()
  return (
    <div className="">
      <div className="flex justify-end">
        <div className="py-8 md:p-8 cursor-pointer" onClick={() => history.push("/")}>
          <div
            className="w-16 h-16 md:w-20 md:h-20"
            style={{
              backgroundImage: `url(${NdlLogoUrl})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default NavBar
