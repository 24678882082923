import HomeElement from "../components/HomeElement"
import NdlLogoUrl from "../assets/ndl_logo.svg"
import HeaderBGUrl from "../assets/rocas.png"
import HeaderBGMDUrl from "../assets/rocas_md.png"
import HeaderBGSMUrl from "../assets/rocas_sm.png"
import ConsultoriaBGUrl from "../assets/NDL_38.png"
import InvestigacionBGUrl from "../assets/NDL_37.png"
import ExperienciasBGUrl from "../assets/NDL_36.png"
import CreatividadBGUrl from "../assets/NDL_42.png"
import ConocimientoBGUrl from "../assets/NDL_40.png"
import ProyectosBGUrl from "../assets/NDL_41.png"
import { useHistory } from "react-router-dom"
import { useBreakpoint } from "../components/hooks/useBreakpoint"

const Home = () => {
  const history = useHistory()
  const isMobile = useBreakpoint(768)
  const isTablet = useBreakpoint(1200)

  return (
    <div>
      <div
        className="h-96 relative"
        style={{
          height: "90vh",
          marginBottom: "30vh",
        }}
      >
        <div
          className="inset-0 bottom-20 absolute"
          style={{
            backgroundImage: `url(${isMobile ? HeaderBGSMUrl : isTablet ? HeaderBGMDUrl : HeaderBGUrl})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <div className="inset-0 bottom-10 absolute flex justify-center items-end">
          <div
            className="w-64 h-64"
            style={{
              backgroundImage: `url(${NdlLogoUrl})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </div>
      </div>

      <div className="flex justify-center">
        <div className="max-w-4xl">
          <div className="flex flex-col md:min-h-screen">
            <div className="flex flex-col sm:flex-row justify-end">
              <HomeElement
                // date="2021"
                label="CONSULTORÍA"
                backgroundSrc={`url(${ConsultoriaBGUrl})`}
                onClick={() => history.push("/consultoria")}
              />
            </div>
            <div className="flex flex-col sm:flex-row justify-end">
              <HomeElement
                // date="2021"
                label="INVESTIGACIÓN"
                backgroundSrc={`url(${InvestigacionBGUrl})`}
                onClick={() => history.push("/investigacion")}
              />
              <HomeElement
                // date="2021"
                label="EXPERIENCIAS"
                backgroundSrc={`url(${ExperienciasBGUrl})`}
                onClick={() => history.push("/experiencias")}
              />
            </div>
            <div className="flex flex-col sm:flex-row justify-end">
              <HomeElement
                // date="2021"
                label="CREATIVIDAD Y SERENDIPIA"
                backgroundSrc={`url(${CreatividadBGUrl})`}
                onClick={() => history.push("/creatividad-y-serendipia")}
              />
              <HomeElement
                // date="2021"
                label="INTERCAMBIO DE CONOCIMIENTOS"
                backgroundSrc={`url(${ConocimientoBGUrl})`}
                onClick={() => history.push("/intercambio-de-conocimientos")}
              />
              <HomeElement
                // date="2021"
                label="PROYECTOS COLECTIVOS"
                backgroundSrc={`url(${ProyectosBGUrl})`}
                onClick={() => history.push("/proyectos-colectivos")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
