import MainRouter from "../router/MainRouter"

export const Layout = () => (
  <div>
    <MainRouter />
    <Footer />
  </div>
)

export const Footer = () => {
  const currentYear = new Date().getFullYear()
  return (
    <div className="text-center text-sm my-4 opacity-75">
      <div>
        Búscanos en{" "}
        <a className="underline" href="mailto:dyslexicnature@gmail.com">
          dyslexicnature@gmail.com
        </a>
      </div>
      <div>Nature Design Lab &copy; {currentYear}</div>
    </div>
  )
}
