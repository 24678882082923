import { PageFooter, GoBackButton, PageHeader, PageLayout } from "../layouts/PageLayout"

const Consultoria = () => {
  return (
    <PageLayout
      header={<PageHeader>Consultoría</PageHeader>}
      footer={
        <PageFooter>
          <GoBackButton>Regresar</GoBackButton>
        </PageFooter>
      }
    >
      <div>
        Diseño centrado en la vida y el desarrollo de ideas basadas en la
        adaptación del contexto que las contienen y entregan valor al integrar
        principios de vida. <br />
        <br />
        Mapeo y complejidad de nuestras ideas y oportunidades. <br />
        <br />
        Diseño inspirado en la naturaleza, diseño que emula las estrategias de
        organismos y ecosistemas.
      </div>
    </PageLayout>
  )
}

export default Consultoria
