import { Route, Switch } from "react-router"
import Conocimiento from "../pages/Conocimiento"
import Consultoria from "../pages/Consultoria"
import Creatividad from "../pages/Creatividad"
import Experiencias from "../pages/Experiencias"
import Home from "../pages/Home"
import Investigacion from "../pages/Investigacion"
import Proyectos from "../pages/Proyectos"
import NuestraNaturaleza from "../pages/NuestraNaturaleza"

const MainRouter = () => {
  return (
    <Switch>
      <Route path="/consultoria">
        <Consultoria />
      </Route>
      <Route path="/investigacion">
        <Investigacion />
      </Route>
      <Route path="/experiencias">
        <Experiencias />
      </Route>
      <Route path="/creatividad-y-serendipia">
        <Creatividad />
      </Route>
      <Route path="/intercambio-de-conocimientos">
        <Conocimiento />
      </Route>
      <Route path="/proyectos-colectivos">
        <Proyectos />
      </Route>
      <Route path="/nuestranaturaleza">
        <NuestraNaturaleza />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  )
}

export default MainRouter
