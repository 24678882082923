import { PageFooter, GoBackButton, PageHeader, PageLayout } from "../layouts/PageLayout"

const Conocimiento = () => {
  return (
    <PageLayout
      header={<PageHeader>Intercambio de Conocimiento</PageHeader>}
      footer={
        <PageFooter>
          <GoBackButton>Regresar</GoBackButton>
        </PageFooter>
      }
    >
      <div>
        Aprendo tú aprendo yo, talleres, conferencias y clases.
        <br />
        Creemos profundamente que el saber es derecho de todos y que el
        conocimiento se comparte como la comida y el agua. <br />
        <br />
        Nuestros talleres, conferencias y clases principalmente se comprende por
        los siguientes temas:
        <br />
        Creatividad <br />
        Diseño
        <br />
        Biomimesis
        <br />
      </div>
    </PageLayout>
  )
}

export default Conocimiento
