export const ImageContainer = ({
  children,
  className = "w-16 h-16 md:w-20 md:h-20",
  imageUrl = null,
  backgroundRepeat = "no-repeat",
  backgroundSize = "cover",
  backgroundPosition = "center",
}) => (
  <div
    className={className}
    style={{
      backgroundImage: `url(${imageUrl})`,
      backgroundRepeat: backgroundRepeat,
      backgroundSize: backgroundSize,
      backgroundPosition: backgroundPosition,
    }}
  >
    {children}
  </div>
)
