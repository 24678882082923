import { ImageContainer } from "../components/ImageContainer"
import { MoonFlowerPageHeader, PageLayout } from "../layouts/PageLayout"

import elefanteImageUrl from "../assets/nuestranaturaleza/elefante_md.png"
import pataImageUrl from "../assets/nuestranaturaleza/pata_md.png"
import bioMariaImageUrl from "../assets/nuestranaturaleza/maria.png"
import bioPauImageUrl from "../assets/nuestranaturaleza/pau.png"
import bioAcoImageUrl from "../assets/nuestranaturaleza/aco.png"
import bioDerImageUrl from "../assets/nuestranaturaleza/der.png"

const NuestraNaturaleza = () => {
  return (
    <PageLayout
      header={
        <MoonFlowerPageHeader className="text-6xl md:text-8xl mb-10">
          APUNTES DE <br />
          NUESTRA NATURALEZA
        </MoonFlowerPageHeader>
      }
    >
      <Paragraph>
        Esta libreta fue diseñada para que la lleves contigo cada vez que salgas
        al aire libre o cuando convivas con otras formas de vida. Queremos
        acompañarte en el proceso de adoptar la práctica diaria como
        naturalista.
      </Paragraph>
      <Paragraph>
        Cuando estés listo puedes compartir con los demás tus apuntes y
        anotaciones. La intención de tus notas es comunicar tus observaciones y
        conectar con la naturaleza, y no tiene que ser una obra de arte para que
        pueda ser apreciado. El respeto por nuestro propio trabajo y por el de
        los demás es clave.
      </Paragraph>
      <Paragraph>
        Entra a la página de Nuestra Naturaleza haciendo{" "}
        <a
          href="https://nuestranaturaleza.com"
          className="hover:underline font-bold"
        >
          click aquí
        </a>{" "}
        y regístrate, recuerda puedes subir cuantos dibujos quieras y asegurate
        de tomar una buena foto con suficiente resolución, tus apuntes de
        nuestra naturaleza se verán mejor que nunca. Explora los ejemplos que
        tenemos en nuestra página y disfruta de observar los dibujos y apuntes
        de otros.
      </Paragraph>
      <div className="flex justify-center mt-16">
        <ImageContainer
          imageUrl={elefanteImageUrl}
          backgroundSize="contain"
          className="w-60 h-60 md:w-72 md:h-72"
        />
      </div>

      <div className="my-40 md:my-60">
        <MoonFlowerPageHeader className="text-4xl md:text-6xl mb-10">
          MÁS SOBRE EL PROYECTO Y NOSOTROS
        </MoonFlowerPageHeader>

        <Paragraph>
          El proyecto de Nuestra Naturaleza nace con la intención de escuchar a
          niñas y niños reflexionar sobre su estrecha relación con la
          naturaleza. Cada persona tiene una relación única con la vida que
          habita en la tierra.
        </Paragraph>
        <Paragraph>
          Nuestro propósito es conectar y aprender de la naturaleza, cuando
          hacemos conciencia de los fenómenos, formas y patrones que ocurren a
          nuestro alrededor y dejamos nuestros apuntes registrados en una
          bitácora, realizamos un ejercicio de atención con intención, que nos
          sirve como referencia para recordar más vívidamente esos momentos de
          asombro ocasionando emociones que se quedan grabadas en la memoria a
          largo plazo.
        </Paragraph>
        <Paragraph>
          La oportunidad de trabajar de cerca con parques y zoológicos para
          transformar la educación al aire libre, invitando a la biomímesis a
          participar activamente en la conversación. Al utilizar esta libreta de
          apuntes, compartimos la visión y el reconocimiento de estos espacios
          como laboratorios de ideas, convirtiéndose en centros de aprendizaje
          donde los visitantes pueden explorar de cerca a los diferentes
          organismos, conectar y asombrarse de la inmensa genialidad de la vida.
        </Paragraph>
      </div>

      <div className="my-40">
        <MoonFlowerPageHeader className="text-4xl md:text-6xl mb-10">
          CONOCE AL EQUIPO
        </MoonFlowerPageHeader>
        <div className="mb-16">
          <Paragraph>
            Somos un grupo diverso de mujeres que buscamos un sueño en común,
            buscamos un mundo donde la naturaleza y lo que podemos aprender de
            ella sea parte de los contenidos de enseñanza y programas educativos
            en México y Latinoamérica.
          </Paragraph>
          <Paragraph>
            Imaginamos un mundo donde la naturaleza es nuestro mentor y guía, lo
            que nos hace capaces de habitar en esta tierra en sintonía con otras
            formas de vida y crear prosperidad.
          </Paragraph>
        </div>
        <BiographyContainer
          className="my-16"
          imageUrl={bioMariaImageUrl}
          right="MARÍA SÁNCHEZ GAVITO"
        />
        <BiographyContainer
          className="my-16"
          imageUrl={bioPauImageUrl}
          left="PAULINA TREJO BARONCIO"
        />
        <BiographyContainer
          className="my-16"
          imageUrl={bioAcoImageUrl}
          right="ACOYANI MARTÍNEZ CAMARGO"
        />
        <BiographyContainer
          className="my-16"
          imageUrl={bioDerImageUrl}
          left="DANIELA ESPONDA RODRÍGUEZ"
        />
      </div>

      <div className="my-40">
        <Paragraph>
          Los naturalistas de hoy en día somos todos nosotros, nos inspiramos,
          conectamos y descubrimos la genialidad de la naturaleza, todo esto es
          una camino a la innovación, convirtiéndonos en agentes de cambio.
        </Paragraph>
      </div>

      <div className="flex justify-center my-40">
        <ImageContainer
          imageUrl={pataImageUrl}
          backgroundSize="contain"
          className="w-60 h-60 md:w-72 md:h-72"
        />
      </div>

      <MoonFlowerPageHeader className="text-6xl md:text-9xl text-center my-80">
        <a href="https://nuestranaturaleza.com">
          NUESTRA <br /> NATURALEZA
        </a>
      </MoonFlowerPageHeader>
    </PageLayout>
  )
}

const Paragraph = ({ children, className = "mt-4" }) => (
  <div className={className}>{children}</div>
)

const BiographyContainer = ({
  left = null,
  right = null,
  imageUrl,
  className,
}) => {
  const flexGrowShrinkClass = "flex-grow flex-shrink "
  const hiddenMobileClass = "hidden md:block"
  return (
    <div className={"flex justify-center items-center " + className}>
      <div
        style={{ flexBasis: "50%" }}
        className={flexGrowShrinkClass + (left ? "" : hiddenMobileClass)}
      >
        {left && (
          <MoonFlowerPageHeader className="text-3xl md:text-4xl text-right">
            {left}
          </MoonFlowerPageHeader>
        )}
      </div>
      <div style={{ flexBasis: 0 }} className="flex-grow-0 flex-shrink-0 px-4">
        <BiographyImage imageUrl={imageUrl} />
      </div>
      <div
        style={{ flexBasis: "50%" }}
        className={flexGrowShrinkClass + (right ? "" : hiddenMobileClass)}
      >
        {right && (
          <MoonFlowerPageHeader className="text-3xl md:text-4xl text-left">
            {right}
          </MoonFlowerPageHeader>
        )}
      </div>
    </div>
  )
}

const BiographyImage = ({
  imageUrl,
  containerSizeClass = "w-32 h-32 md:w-36 md:h-36",
  imageSizeClass = "w-32 h-32 md:w-36 md:h-36",
}) => (
  <div
    className={
      "relative rounded-full border border-solid border-black overflow-hidden " +
      containerSizeClass
    }
  >
    <ImageContainer
      imageUrl={imageUrl}
      className={
        "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 " +
        imageSizeClass
      }
    />
  </div>
)

export default NuestraNaturaleza
