import { PageFooter, GoBackButton, PageHeader, PageLayout } from "../layouts/PageLayout"

const Investigacion = () => {
  return (
    <PageLayout
      header={<PageHeader>Investigación</PageHeader>}
      footer={
        <PageFooter>
          <GoBackButton>Regresar</GoBackButton>
        </PageFooter>
      }
    >
      <div>
        <i>"From the will to the design table"</i>, proceso único basado en las
        metodologías de biomimicry donde la creatividad comienza con el
        descubrimiento y la curiosidad en la naturaleza y luego informa a los
        procesos de creación y diseño sostenible.
        <br /> La tierra al centro, desarrollo de proyectos de investigación
        basados en la genialidad local de un ecosistema.
      </div>
    </PageLayout>
  )
}

export default Investigacion
