import { useHistory } from "react-router-dom"
import NavBar from "../components/NavBar"

export const PageLayout = ({ children, header = null, footer = null }) => {
  return (
    <div className="mx-8 md:mx-64">
      <NavBar />
      <div className="flex justify-center">
        <div className="max-w-4xl">
          {header}
          <div className="">{children}</div>
          {footer}
        </div>
      </div>
    </div>
  )
}

export const PageHeader = ({
  children,
  style,
  className = "text-4xl md:text-7xl mb-10",
}) => (
  <div className={className} style={style}>
    {children}
  </div>
)

export const MoonFlowerPageHeader = ({ children, className = "text-4xl md:text-7xl mb-10" }) => (
  <PageHeader className={className} style={{ fontFamily: "MoonFlower" }}>{children}</PageHeader>
)

export const PageFooter = ({ children }) => (
  <div className="flex mt-8">{children}</div>
)

export const GoBackButton = ({ children }) => {
  let history = useHistory()
  return (
    <div
      className="text-sm font-bold opacity-50 cursor-pointer py-8 pr-8"
      onClick={() => history.goBack()}
    >
      &#8592; {children}
    </div>
  )
}
