import { PageFooter, GoBackButton, PageHeader, PageLayout } from "../layouts/PageLayout"

const Creatividad = () => {
  return (
    <PageLayout
      header={<PageHeader>Creatividad y serendipia</PageHeader>}
      footer={
        <PageFooter>
          <GoBackButton>Regresar</GoBackButton>
        </PageFooter>
      }
    >
      <div>¿Necesitas una chispa creativa? Te ayudamos.</div>
    </PageLayout>
  )
}

export default Creatividad
